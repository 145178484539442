<template>
  <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
    <BaseSelectField
      v-if="baseSelectField"
      :value="value"
      :label="label"
      :required="required"
      @input="$emit('input', $event)"
      :items="items"
      :disabled="disabled"
    ></BaseSelectField>
    <SelectField
      v-else
      :value="value"
      :disabled="disabled"
      :type="type"
      :label="label"
      :errors="errors"
      :clearable="clearable"
      :required="required"
      @input="$emit('input', $event)"
      :counter="counter"
      :items="items"
    ></SelectField>
  </ValidationProvider>
</template>

<script>
import SelectField from "@/components/fields/SelectField";
import BaseSelectField from "@/components/fields/BaseSelectField";
export default {
  name: "ValidationSelectField",
  components: { SelectField, BaseSelectField },
  props: {
    items: {
      type: Array,
      require: true
    },
    type: {
      type: String
    },

    clearable: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String
    },
    validateName: {
      type: [String, Number, Boolean]
    },
    disabled: {
      type: Boolean
    },
    counter: {
      type: [Boolean, Number, String]
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Boolean]
    },
    required: {
      type: Boolean,
      default: false
    },
    baseSelectField: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    name() {
      return this.validateName || this.label;
    }
  }
};
</script>

<style scoped></style>
